
import { defineComponent } from 'vue'
import { getBreadCrumbs } from '~/util/routeFunctions'
export default defineComponent({
  data () {
    return {
      pathArr: [] as ReturnType<typeof getBreadCrumbs>
    }
  },
  mounted () {
    this.pathArr = getBreadCrumbs(this.$route.path)
  }
})

