export const routeNames = {
  '': 'Arthall',
  'about-us': 'О нас',
  paintings: 'Картины',
  artists: 'Художники',
  news: 'Лента новостей',
  'join-arthall': 'Как попасть в Arthall'
}

export const getBreadCrumbs = (path: string, idTitles: string[] = []): Array<{title: string, href: string}> => {
  if (path.endsWith('/')) { path = path.slice(0, -1) }
  const pathsArr = path.split('/')
  if (pathsArr.length < 2) { return [] }

  let currentHref = window.location.origin

  return pathsArr
    .map((pathEl) => {
      currentHref += `${Number.isInteger(pathEl) && idTitles.length > 0 ? idTitles?.shift() : pathEl}/`

      return {
        title: routeNames[pathEl],
        href: currentHref
      }
    })
}
