
import { Component, Ref, Vue, Watch } from 'nuxt-property-decorator'
import LayoutHeader from '~/components/layout/LayoutHeader.vue'
import LayoutFooter from '~/components/layout/LayoutFooter.vue'
import LayoutBreadcrumbs from '~/components/layout/LayoutBreadcrumbs.vue'
import FullScreenImage from '~/components/index/FullScreenImage.vue'
// import PromoCodePopup from '~/components/popups/PromoCodePopup.vue'

@Component({
  components: {
    // PromoCodePopup,
    FullScreenImage,
    LayoutFooter,
    LayoutHeader,
    LayoutBreadcrumbs
  }
})
export default class extends Vue {
  @Ref('content') content!: HTMLDivElement

  isAnalyticaInit = false

  /** скролим наверх при изменении урла */
  @Watch('$route.fullPath')
  onRouteChange () {
    this.content.scrollTop = 0
  }

  mounted () {
    window.addEventListener('mousemove', this.initAnalytica)
    window.addEventListener('scroll', this.initAnalytica)
  }

  initAnalytica () {
    if (!this.isAnalyticaInit) {
      this.isAnalyticaInit = true
      window.removeEventListener('mousemove', this.initAnalytica)
      window.removeEventListener('scroll', this.initAnalytica)

      const script = document.createElement('script')
      script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-187763789-1'
      script.async = true
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }
}
