import { ref } from 'vue'
let init

export const useWindowHandlers = () => {
  if (init) { return init }

  window.addEventListener('resize', (ev) => {
    windowHandlers.value.resize.forEach(
      (fnc) => {
        fnc(ev)
      }
    )
  })

  const windowHandlers = ref({
    nextId: 0,
    resize: [],

    listen (type, fnc, launchOnAdd = false) {
      if (!(type in this)) { return }

      this[type].push(fnc)

      if (launchOnAdd) { fnc() }
    },
    stopListening (type, fnc) {
      if (!(type in this)) { return }

      const index = this[type].findIndex(fncEl => fncEl === fnc)
      if (index === -1) { return }

      this[type].splice(index, 1)
    }
  })

  init = windowHandlers.value

  return windowHandlers.value
}
